import { IoCopyOutline } from "react-icons/io5";
import toast, { Toaster } from "react-hot-toast";
import { GiMeat } from "react-icons/gi";
function App() {
  const handleCopy = () => {
    const text = "GtoXehCpYPkW4JNxYCwE1wAgXUGnJuANBvZzxA7qpump";
    navigator.clipboard.writeText(text).then(
      () => {
        toast.success("Token Address Copied", {
          style: {
            border: "1px solid #0e1b00",
            padding: "16px",
            color: "#0e1b00",
          },
          iconTheme: {
            primary: "#a28581",
            secondary: "#0e1b00",
          },
        });
      },
      (err) => {
        console.error("Failed to copy text: ", err);
      }
    );
  };
  return (
    <div
      className="h-lvh overflow-hidden bg-no-repeat bg-center  bg-cover"
      style={{ backgroundImage: "url(assets/images/background.jpeg)" }}
    >
      <div className="flex h-full flex-col lg:flex-row">
        <div className="flex flex-col w-full lg:flex-1 items-center p-3 justify-center">
          <h1 className="text-center text-4xl md:text-6xl font-bold text-[#a28581] leading-normal">
            The biggest meat on solana
          </h1>
          <h1 className="text-4xl md:text-6xl text-[#a28581]"><GiMeat /></h1>
        </div>
        <div
          className="flex-1 bg-no-repeat bg-center bg-contain  lg:bg-contain"
          style={{ backgroundImage: "url(assets/images/logo.png)" }}
        ></div>
        <div className="flex flex-col w-full lg:flex-1 items-center p-3 justify-center">
          <img src="assets/images/solana.png" className="w-24 h-24 mb-3" alt="solana" />
          <a
            href="https://pump.fun/GtoXehCpYPkW4JNxYCwE1wAgXUGnJuANBvZzxA7qpump"
            className="font-semibold bg-[#a28581] text-[#0e1b00]  px-8 py-2 text-2xl rounded-2xl mb-3"
          >
            Buy
          </a>
          <div className="bg-[#a28581] py-5 md:px-5 rounded">
            <button
              onClick={handleCopy}
              className="bg-[#0e1b00] text-[#a28581] w-max text-4xl mx-auto flex items-center p-3 rounded"
            >
              GtoXeh...7qpump
              <IoCopyOutline />
            </button>
          </div>
        </div>
      </div>
      <Toaster position="bottom-center" />
    </div>
  );
}

export default App;
